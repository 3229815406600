<template>
    <div class="dishes-page" :class="isPopupAuth || isShowProduct ? 'blur' : ''">
        <div class="dishes pt">
            <div class="dishes__container _container">
                <div class="dishes__columns">
                    <aside class="sidebar">
                        <div class="find">
                            <t-btn-arrow @click="$router.push('/welcome')" />
                            <button class="btn-find btn-circle" @click="isPopupKeyboad = !isPopupKeyboad">
                                <img src="@/assets/img/svg/find.svg" alt="find" width="30.9" />
                            </button>
                        </div>
                        <h2 class="title">{{ title }}</h2>
                        <div class="t-coin">
                            <template v-if="token">
                                <div class="btn-disabled">
                                    <div class="user">
                                        <img src="@/assets/img/svg/user.svg" alt="user" />
                                    </div>
                                    <div class="bonuses">{{ bonuses }}</div>
                                    <div class="coins">
                                        <img src="@/assets/img/svg/t-coins.svg" alt="t-coins" />
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="btn-coin" @click="isPopupAuth = true">
                                    <img src="@/assets/img/svg/coin.svg" alt="coin" width="46" /><span>{{
                                        addCoins
                                    }}</span>
                                </div>
                            </template>
                        </div>
                    </aside>
                    <div class="dishes__body">
                        <div class="block-menu">
                            <ul class="menu">
                                <template v-for="category in categories" :key="category.id">
                                    <li
                                        v-if="
                                            category.id !== 2001 &&
                                            category.id !== 2008 &&
                                            category.id !== 2014 &&
                                            category.id !== 2015 &&
                                            category.id !== 2017 &&
                                            category.id !== 2199
                                        "
                                    >
                                        <a
                                            @click="changeCategory(category.id), (isFastSets = false)"
                                            class="menu__item"
                                            :class="selectedCategoryId === category.id ? `active` : false"
                                            >{{ category.real_name }}</a
                                        >
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="filter-popup" v-if="isFilter">
                            <div class="filter-popup__block">
                                <div class="close" @click="isFilter = false">
                                    <img src="@/assets/img/svg/close.svg" alt="close" />
                                </div>
                                <div class="filter-popup__list">
                                    <label class="checkbox-other"> <input type="checkbox" /> Хит </label>
                                    <label class="checkbox-other"> <input type="checkbox" /> Новинка </label>
                                    <label class="checkbox-other"> <input type="checkbox" /> Без мяса </label>
                                    <label class="checkbox-other"> <input type="checkbox" /> Для детей </label>
                                    <div class="reset">
                                        <span><img src="@/assets/img/svg/reset.svg" alt="reset" /></span>
                                        Сбросить
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="search-nothing" v-if="!products.length">
                            {{ searchNothing }}
                        </div>
                        <div class="blocks">
                            <div
                                class="block"
                                v-for="product in products"
                                :key="product.id"
                                :class="product.is_popular ? `popular` : ``"
                            >
                                <div class="block__img" @click="showProductId(product.id, product)">
                                    <div class="tagProduct hit" v-if="product.is_popular">Хит</div>
                                    <img
                                        :src="product.image ? product.image : require(`@/assets/img/${nophoto}`)"
                                        :alt="product.real_name"
                                    />
                                    <div class="count" v-if="product.count">
                                        {{ product.count }}
                                    </div>
                                </div>
                                <div class="block__body">
                                    <div class="block__title" @click="showProductId(product.id, product)">
                                        {{
                                            product.real_name.length > 50
                                                ? product.real_name.substr(0, 50) + "..."
                                                : product.real_name
                                        }}
                                    </div>
                                    <div>
                                        <div
                                            class="block-counter"
                                            v-if="
                                                product.modifiers.length > 0 &&
                                                !product.modifiers_as_hint &&
                                                !product.count
                                            "
                                        >
                                            <span>{{ product.price }} ₽</span>
                                            <button
                                                class="btn-green add-to-cart"
                                                @click="showProductId(product.id, product)"
                                            >
                                                +
                                            </button>
                                        </div>
                                        <counter-price v-else :product="product" @total-count-cart="totalCountCart" />
                                        <!-- <div @click="addToCart(product)" class="btn-more">
                      <span>Нет в наличии</span>
                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="bag" @click="this.$router.push('/cart')">
                <img src="@/assets/img/svg/cart.svg" alt="cart" />
                <span class="count">{{ totalCountCart() }}</span>
            </button>
        </div>
        <popup-keyboad
            class="keyboard-block popupSearch"
            v-if="isPopupKeyboad"
            @click="isPopupKeyboad = false"
            @is-popup-keyboard="isPopupKeyboad = false"
        />
    </div>
    <div class="productPopup" v-if="isShowProduct" @click="isShowProduct = false">
        <popup-product
            v-for="product in products"
            :key="product.id"
            :product="product"
            :product_id="product_id"
            @is-show-product="isShowProduct = false"
            @add-to-order="addToOrder"
            :showPrice="showPrice"
            :getOrderItem="getOrderItem"
            @modifier-change="modifierChange"
            @handle-tab-click="handleTabClick"
            :currentTab="currentTab"
            :orderObj="orderObj"
        />
    </div>
    <popup-auth v-if="isPopupAuth" @click="closeAuth" @close-auth="closeAuth" />
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";
import CounterPrice from "@/components/counters/CounterPrice.vue";
import PopupAuth from "@/components/popups/PopupAuth.vue";
import PopupProduct from "@/components/popups/PopupProduct.vue";
import PopupKeyboad from "@/components/popups/PopupKeyboad.vue";
export default {
    components: { CounterPrice, PopupAuth, PopupProduct, PopupKeyboad },
    name: "t-dishes",
    data() {
        return {
            isPopupAuth: false,
            isShowProduct: false,
            isFastSets: false,
            isPopupKeyboad: false,
            categories: [],
            selectedCategoryId: 0,
            products: [],
            searchNothing: `Загрузка блюд...`,
            title: "Заказ на подносе",
            addCoins: "Копить Т-коины",
            isFilter: false,
            product_id: null,
            bonuses: 0,
            token: localStorage.getItem("token"),
            count: 0,
            nophoto: "nophoto.jpg",
            cafe_slug: "",
            orderObj: { items: [] },
            currentTab: {},
            product: {},
        };
    },
    methods: {
        modifierChange(mod_id, order_id, type, product_id, order_item_id) {
            let self = this;
            axios
                .post(api + `order-change/`, {
                    order_id,
                    product_id,
                    mod_id,
                    type,
                    order_item_id,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                    self.handleTabClick(self.currentTab);
                });
        },
        getOrderItem: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    output = item.id;
                }
            });
            return output;
        },
        handleTabClick(tab) {
            this.currentTab = {
                ...tab,
                product: this.orderObj.items.find((item) => item.selected_modifier === tab.id),
            };
            this.currentTab.price = this.product.price * this.currentTab.quantity;
        },
        addToOrder(order_id, product_id) {
            let self = this;
            axios
                .post(api + `order-add/`, {
                    order_id: order_id,
                    product_id: product_id,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        showPrice: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    output = item.product.price * item.quantity;
                }
            });
            return output;
        },
        closeAuth() {
            this.isPopupAuth = false;
        },
        showProductId(product_id, product) {
            console.log(product_id);
            this.product_id = product_id;
            this.isShowProduct = true;
            if (product.modifiers.length > 0 && !product.modifiers_as_hint) {
                this.currentTab = product.modifiers[0];
            }
        },
        changeCategory: function (categoryId) {
            this.selectedCategoryId = categoryId;
            axios
                .get(
                    `https://admin.tcode.online/api/projects/tubatay/shops/${this.cafe_slug}/products/?category=` +
                        this.selectedCategoryId
                )
                .then((response) => {
                    this.products = response.data.results;
                })
                .catch((e) => {
                    this.errors.cafes = e.message;
                    this.errors = e.message;
                });
        },
        totalCountCart() {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            let items = [];

            if (array) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].type !== "discounted") {
                        items.push(array[i]);
                    }
                }
            }

            return items.reduce((acc, item) => acc + item.count, 0);
        },
        plusToCart(product) {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            elem.count++;
            localStorage.setItem("cart", JSON.stringify(array));
            product.count = elem.count;
        },
        minusToCart(product) {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            const index = array.findIndex((item) => item.id === product.id);
            if (elem.count > 1) {
                elem.count--;
            } else {
                array.splice(index, 1);
                elem.count = null;
            }
            localStorage.setItem("cart", JSON.stringify(array));
            product.count = elem.count;
        },
        addToCart(product) {
            console.log(product);
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            product.count = 1;
            if (elem) {
                elem.count++;
            } else {
                let productItem = {
                    id: product.id,
                    name: product.real_name,
                    description: product.description,
                    price: product.price,
                    is_popular: true,
                    image: product.image,
                    video: null,
                    picshot: null,
                    thumbnail: product.thumbnail,
                    modifiers: [],
                    modifiers_as_hint: false,
                    energy: product.energy,
                    weight: product.weight,
                    articul: null,
                    weight_measure: "gr",
                    fat: product.fat,
                    proteins: product.proteins,
                    carbohydrates: product.carbohydrates,
                    intro_text: product.intro_text,
                    categoriesId: product.categories.id,
                    categoriesRealName: product.categories.real_name,
                    categoriesImage: product.categories.image,
                    is_favourite: false,
                    count: 1,
                };
                array.push(productItem);
            }
            localStorage.setItem("cart", JSON.stringify(array));
        },
    },
    mounted() {
        this.token ? (this.isPopupAuth = false) : (this.isPopupAuth = true);
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            cafe_slug = "park-house";
        }
        if (this.cafe_slug === "localhost:8080") {
            this.cafe_slug = "park-house";
        }
        //Товары
        axios
            .get(
                `https://admin.tcode.online/api/projects/tubatay/shops/${this.cafe_slug}/products/?available=by_pickup`
            )
            .then((response) => {
                console.log(response.data);
                //this.products = response.data.results;
            })
            .catch((error) => {
                console.log(error);
            });
        //Категории
        axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/${this.cafe_slug}/categories`)
            .then((response) => {
                console.log(response.data);
                this.categories = response.data.results;
                this.selectedCategoryId = this.categories[0].id;
                axios
                    .get(
                        `https://admin.tcode.online/api/projects/tubatay/shops/${this.cafe_slug}/products/?category=` +
                            this.selectedCategoryId
                    )
                    .then((response) => {
                        this.products = response.data.results;
                    })
                    .catch((e) => {
                        this.errors.cafes = e.message;
                        this.errors = e.message;
                    });
            })
            .catch((error) => {
                console.log(error);
            });
        //Тэги
        axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/${this.cafe_slug}/tags/`)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        if (this.token) {
            //Профиль
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/profile/`, {
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
            //бонусы
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/bonuses`, {
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.bonuses = response.data.bonuses;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        //Подарки
        axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/${this.cafe_slug}/gifts/`)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.btn-coin {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: #28735f;
        color: #e4f9f3;
        box-shadow: 0 4px 10.3px 0 rgba(128, 255, 209, 0.5);
        transition: 0.2s ease 0.2s;
    }
    span {
        padding-left: 10px;
    }
}
.btn-disabled {
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.btnFastSets {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px dashed #d36d51;
    border-radius: 12px;
    background: none;
    @media (max-width: $ms3) {
        width: 100%;
        height: 50px;
        span {
            font-size: 16px !important;
        }
    }
    span {
        font-weight: 400;
        font-size: 35px;
        line-height: 25.75px;
        color: #d36d51;
        background: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #fffff7;
        border-radius: 10px;
    }
    &:hover,
    &:focus {
        span {
            color: #fffff7;
            background: #d36d51;
            transition: 0.2s ease 0.2s;
        }
    }
}
.btnFastSets._active {
    span {
        color: #fffff7;
        background: #d36d51;
        transition: 0.2s ease 0.2s;
    }
}
.fast-sets {
    width: 100%;
    max-width: 751px;
    margin: 3rem auto 0;
    height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    position: relative;
    left: 7px;
    @media (max-width: $ms4) {
        padding: 5px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(40, 115, 95, 0.2);
        border-radius: 10px;
        margin-top: 1rem;
    }
    &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-thumb {
        background: #28735f;
        border-radius: 10px;
    }
    .btn-add {
        background: #dae6de;
        font-family: "Nunito";
        font-size: 18px;
        font-weight: 900;
        line-height: 20px;
        text-align: center;
        color: #81ada0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        &:hover,
        &:focus {
            background: #cde6d5;
            transition: 0.2s ease 0.2s;
        }
    }
    &__blocks {
        display: flex;
        flex-direction: column;
    }
    &__block {
        width: 100%;
        height: auto;
        border-radius: 12px;
        background-color: #f9faf5;
        box-shadow: 0 0 5px rgba(105, 105, 105, 0.25);
        margin-bottom: 1.5rem;
    }
    &__sets {
        display: flex;
        justify-content: center;
        overflow: hidden;
        padding: 0 2.5rem 0.5rem;
        @media (max-width: $ms3) {
            padding: 0 1.5rem 0.5rem;
            flex-wrap: wrap;
        }
        @media (max-width: $ms4) {
            padding: 0 0 0.5rem;
        }
    }
    &__set {
        width: 25%;
        height: auto;
        border-radius: 8px;
        overflow: hidden;
        background-color: #fffff7;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0.5rem 0;
        @media (max-width: $ms3) {
            width: 45%;
        }
        @media (max-width: $ms4) {
            width: 40%;
        }
    }
    &__img {
        height: 140px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: scale(1.3);
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 400;
        line-height: 20.6px;
        color: #0e0e0e;
        @media (max-width: $ms2) {
            font-size: 18px;
        }
        @media (max-width: $ms3) {
            font-size: 14px;
        }
    }
    &__change {
        background: none;
        border: 2px solid #d36d51;
        border-radius: 7px;
        position: relative;
        width: 100%;
        height: 45px;
        padding-left: 2rem;
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            content: url("~@/assets/img/svg/change.svg");
            position: absolute;
            top: 3px;
            bottom: 0;
            left: 10px;
            margin: auto;
            display: flex;
            align-items: center;
            transform: scale(0.75);
        }
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
        span {
            font-family: "Nunito";
            font-size: 18px;
            font-weight: 700;
            line-height: 27.28px;
            text-align: center;
            color: #d36d51;
            padding-top: 3px;
            @media (max-width: $ms2) {
                font-size: 16px;
            }
            @media (max-width: $ms3) {
                font-size: 14px;
            }
        }
    }
    &__btn-to-pay {
        width: 95%;
        height: 67px;
        border-radius: 8px;
        background-color: #28735f;
        font-family: "Nunito";
        font-size: 25px;
        font-weight: 800;
        line-height: 34.1px;
        text-align: center;
        color: #fffff7;
        margin: 1rem auto 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover,
        &:focus {
            background-color: #184639;
            transition: 0.2s ease 0.2s;
        }
    }
    &__load {
        display: flex;
        justify-content: center;
    }
    &__btn-load-more {
        width: 320px;
        height: 63px;
        background-color: #dae6de;
        border-radius: 12px;
        font-family: "Nunito";
        font-size: 25px;
        font-weight: 800;
        line-height: 34.1px;
        text-align: center;
        color: #81ada0;
        &:hover,
        &:focus {
            background-color: #81ada0;
            color: #fff;
            transition: 0.2s ease 0.2s;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 auto;
        padding: 10px;
        @media (max-width: $ms4) {
            padding: 0;
        }
    }
}
.sidebar {
    display: flex;
    justify-content: space-between;
    .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 43.65px;
        color: #000;
        display: flex;
        align-items: center;
    }
    .find {
        display: flex;
        align-items: center;
        .btn {
            margin-right: 20px;
        }
    }
    .t-coin {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e4f9f3;
        border-radius: 32px;
        box-shadow: 0 4px 10.3px 0 rgba(128, 255, 209, 0.25);
        font-weight: 700;
        font-size: 22px;
        line-height: 30.01px;
        color: #28735f;
        width: auto;
        height: 64px;
        overflow: hidden;
        .bonuses {
            margin: 0px 5px 0px 20px;
            font-weight: 700;
            font-size: 22px;
            line-height: 30.01px;
            color: #28735f;
        }
        .user {
            width: 39px;
            height: 39px;
            border: 2px solid #28735f;
            border-radius: 50%;
            padding: 5px;
        }
        .coins {
            width: 20px;
            height: 17px;
        }
        .user,
        .coins {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
.dishes {
    background-color: #fff !important;
    overflow: hidden;
    @media (max-width: $ms3) {
        height: auto;
        padding-bottom: 5rem;
    }
    .popular {
        .block__body {
            background-color: $red;
        }
        .block__title,
        .block__info {
            color: #fff;
        }
        .add-to-cart {
            background-color: $beige;
            color: $red;
            font-weight: 700;
            &:hover,
            &:focus {
                background-color: #fdfddf;
                transition: 0.2s ease 0.2s;
            }
        }
        .block-counter,
        .counter__price {
            color: #fff;
        }
    }
    background-color: $beige;
    padding-bottom: 5rem;
    position: relative;
    .bag {
        background: none;
        position: fixed;
        bottom: 1rem;
        right: 4rem;
        width: 112px;
        height: 112px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
        img {
            width: 53px;
            height: 53px;
        }
        .count {
            position: absolute;
            top: 0;
            right: 0;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background: $red;
            font-weight: 700;
            font-size: 22px;
            line-height: 30.01px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__columns {
        display: flex;
        flex-direction: column;
        @media (max-width: $ms3) {
            padding-bottom: 2rem;
        }
    }
    &__body {
        width: 100%;
        margin-top: 2rem;
        padding: 20px 0 0;
    }
    &__title {
        font-weight: 700;
        color: #28735f;
        @include adaptive-font(40, 18, 0);
        @include adaptive-value("line-height", 41, 20, 0);
        position: relative;
        top: 15px;
        margin: 0 0 0 20px;
        @media (max-width: $ms3) {
            margin: 1rem;
        }
    }

    .blocks {
        margin: 30px auto 0;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 80vh;
        padding-bottom: 2rem;
        @media (max-width: $ms2) {
            margin: 50px -5px 0;
        }
        @media (max-width: $ms3) {
            margin: 0 auto;
            padding: 0;
        }

        &::-webkit-scrollbar-track {
            background: rgba(40, 115, 95, 0.2);
            border-radius: 10px;
            margin-top: 28px;
        }

        &::-webkit-scrollbar {
            width: 9px;
        }

        &::-webkit-scrollbar-thumb {
            background: #28735f;
            border-radius: 10px;
        }

        .block {
            background-color: #fff;
            border-radius: 20px;
            flex: 0 0 15.66%;
            margin: 10px;
            box-shadow: 1px 3px 25px 0px rgba(0, 45, 29, 0.1);
            @media (max-width: 1440px) {
                flex: 0 0 18%;
            }
            &__img {
                position: relative;
                height: 280px;
                .info {
                    width: 18.75px;
                    height: 20px;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            @media (max-width: 1027px) {
                flex: 0 0 47%;
            }
            @media (max-width: $ms2) {
                margin: 28px 5px 0;
            }
            @media (max-width: $ms3) {
                min-width: unset;
                flex: 0 0 45%;
            }
            .count {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                color: #fff;
                font-weight: 700;
                font-size: 48px;
                line-height: 65.47px;
                font-family: "Nunito Sans";
            }
        }
    }
}
.block-menu {
    overflow-y: hidden;
    &::-webkit-scrollbar-track {
        background: rgba(40, 115, 95, 0.2);
        border-radius: 10px;
        margin-top: 15px;
    }

    &::-webkit-scrollbar {
        width: 9px;
    }

    &::-webkit-scrollbar-thumb {
        background: #28735f;
        border-radius: 10px;
    }
}
.menu {
    max-height: 1212px;
    display: flex;
    margin: 0 -10px;
    &::-webkit-scrollbar-track {
        background: none;
        border-radius: 10px;
        margin-top: 15px;
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: none;
        border-radius: 10px;
    }

    @media (max-width: $ms3) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 1rem;
        max-height: 200px;
        &::-webkit-scrollbar-track {
            background: rgba(40, 115, 95, 0.2);
        }

        &::-webkit-scrollbar {
            width: 9px;
        }

        &::-webkit-scrollbar-thumb {
            background: #28735f;
        }
    }

    li {
        cursor: pointer;
        margin: 5px 10px;
        flex: 0 0 auto;
    }

    &__item {
        background: #f4f4f4;
        border-radius: 30px;
        font-size: 22px;
        line-height: 30.01px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #717171;
        width: fit-content;
        height: 48px;
        padding: 0 1rem;
        @media (max-width: $ms3) {
            width: 100%;
            height: 50px;
            font-size: 16px;
            line-height: 20px;
        }

        &:hover,
        &:focus {
            background: #e4f9f3;
            color: #317763;
            transition: 0.2s ease 0.2s;
        }
    }

    .active {
        background: #e4f9f3;
        color: #317763;
    }
}
._input {
    box-shadow: inset 0 0 0 2px #28735f;
    transition: 0.3s ease 0.3s;
}
.search-input {
    display: flex;
    align-items: center;
    position: relative;
    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border: 2px solid #f4f4f4;
        border-radius: 32px;
        width: 100%;
        min-width: 874px;
        height: 64px;
        overflow: hidden;
    }
    &__img {
        width: 34.99px;
        height: 40px;
        @media (max-width: 767px) {
            width: 15px;
            height: 20px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__input {
        width: 100%;
    }
    &__remove {
        width: 32px;
        height: 32px;
        cursor: pointer;
        z-index: 1;
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.search-nothing {
    padding: 0 2rem;
    text-align: center;
    font-weight: 600;
    color: #28735f;
    font-family: "Nunito", sans-serif;
    font-size: clamp(14px, 5vw, 24px);
    white-space: pre-line;
    line-height: 1rem;
    margin-top: 5rem;
}
.pressed {
    background-color: #d36d51;
    &:hover,
    &:focus {
        background-color: #d35837;
        transition: 0.2s ease 0.2s;
    }
}
.form_toggle {
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: #f4f4f4;
    width: 100%;
    height: 64px;
    border-radius: 10px;
    padding: 0 3px;
    margin-bottom: 30px;
}
.form_toggle-item {
    width: 50%;
    height: 52.8px;
    border-radius: 7px;
    font-size: 20px;
    line-height: 27.28px;
    color: #717171;
    background: none;
    margin: 0 3px;
    overflow: hidden;
}
.form_toggle-item input[type="radio"] {
    display: none;
}
.form_toggle-item label {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 34px;
    cursor: pointer;
    user-select: none;
    flex: 1 1 auto;
    height: 100%;
}
/* Checked */
.form_toggle .form_toggle-item input[type="radio"]:checked + label {
    background: #fff;
    color: #317763;
    font-weight: 700;
}
</style>
