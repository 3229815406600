<template>
    <div class="counter" v-if="product.count">
        <button class="counter__minus" @click="minusToCart(product), $emit('totalCountCart')">-</button>
        <span class="counter__price"
            ><template v-if="isHide">{{ product.count }} х </template>
            {{ product.count ? product.price * product.count : product.price }}
            ₽</span
        >
        <button class="counter__plus" @click="plusToCart(product), $emit('totalCountCart')">+</button>
    </div>
    <div class="block-counter" v-else>
        <span v-if="!isHide">{{ product.price }} ₽</span>
        <button class="btn-green add-to-cart" @click="addToCart(product), $emit('totalCountCart')">
            <template v-if="isHide">
                <span>{{ product.price }} ₽</span>
            </template>
            <template v-else>+</template>
        </button>
    </div>
</template>

<script>
export default {
    name: "t-counter-price",
    props: ["product", "isHide"],
    methods: {
        plusToCart(product) {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            elem.count++;
            localStorage.setItem("cart", JSON.stringify(array));
            product.count = elem.count;
        },
        minusToCart(product) {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            const index = array.findIndex((item) => item.id === product.id);
            if (elem.count > 1) {
                elem.count--;
            } else {
                array.splice(index, 1);
                elem.count = null;
            }
            localStorage.setItem("cart", JSON.stringify(array));
            product.count = elem.count;
        },
        addToCart(product) {
            console.log(product);
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            product.count = 1;
            if (elem) {
                elem.count++;
            } else {
                let productItem = {
                    id: product.id,
                    name: product.real_name,
                    description: product.description,
                    price: product.price,
                    is_popular: true,
                    image: product.image,
                    video: null,
                    picshot: null,
                    thumbnail: product.thumbnail,
                    modifiers: [],
                    modifiers_as_hint: false,
                    energy: product.energy,
                    weight: product.weight,
                    articul: null,
                    weight_measure: "gr",
                    fat: product.fat,
                    proteins: product.proteins,
                    carbohydrates: product.carbohydrates,
                    intro_text: product.intro_text,
                    categoriesId: product.categories.id,
                    categoriesRealName: product.categories.real_name,
                    categoriesImage: product.categories.image,
                    is_favourite: false,
                    count: 1,
                };
                array.push(productItem);
            }
            localStorage.setItem("cart", JSON.stringify(array));
        },
        totalCountCart() {
            this.$emit("totalCountCart");
        },
    },
};
</script>
